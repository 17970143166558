import React from 'react';
const TrustBoxMiniCarousel = () => {

  const ref = React.useRef(null);
  React.useEffect(() => {

if (window.Trustpilot) {
  window.Trustpilot.loadFromElement(ref.current, true);
}
  }, []);
  return (
    <div
       ref={ref}
       className="trustpilot-widget"
       data-locale="nl-NL"
       data-template-id="539ad0ffdec7e10e686debd7"
       data-businessunit-id="58fe12490000ff0005a11d05"
       data-style-height="350px"
       data-style-width="300px"
       data-theme="light"
       data-stars="1,2,3,4,5"
       data-review-languages="nl"
       data-text-color="#272838"
      >
      <a href="https://nl.trustpilot.com/review/employes.nl" target="_blank" rel="noopener"> Trustpilot</a>
    </div>
  );
};

export default TrustBoxMiniCarousel;
