import React from 'react'
import { Link } from 'gatsby'
import * as images from 'images/home'
import Helmet from 'react-helmet'
import { heroKlantenservice } from 'images/hulp/klantenservice'
import Layout from '../../../layouts'
import TrustBoxMiniCarousel from 'components/trustbox-mini-carousel'
import TrustBox from 'components/trustpilot'
import Checkmark from 'components/misc/checkmark'
const {
  logos: { ...logos }
} = images

class DemoPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      active: false,
      activeID: ''
    }

    this.setActive = this.setActive.bind(this)
  }

  setActive(event) {
    let id = event.target.id

    if(id === this.state.activeID) {
      id = ''
    }

    this.setState({
      active: !this.state.active,
      activeID: id
    })
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <main className="demo animated fadeInPage">

          <Helmet>
            <title>Word een held in HR administratie | Employes</title>
            <meta name="description" content="Focus op je werknemers in plaats van processen. In dit whitepaper ontdek je hoe HR software uitkomst biedt."/>
            <meta itemprop="name" content="Whitepaper HR administratie | Employes" />
            <meta itemprop="description" content="Wil je je HR administratie anders aanpakken? In dit whitepaper ontdek je waarom HR software uitkomst biedt." />
            <meta itemprop="image" content="/static/meta-img.png" />
            <meta name="robots" content="noindex,follow" />
            <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>
          </Helmet>

          <header className="header padding-xl">
            <div className="container-md relative">
              <div className="grid yg align-middle">

                <div className="col-6">
                  <div className="title margin-m-bottom">
                    <h5 className="eyebrow turq no-margin">Whitepaper</h5>
                    <h1>Hoe word je een held in HR administratie?</h1>
                    <p className="streamer margin-s-bottom">Tijd voor een andere aanpak van je HR administratie? Soms is je huidige personeelsadministratie aan een update toe. Dit komt meestal doordat:</p>
                    <ul className="streamer margin-s-bottom">
                    <li className="streamer">1. Je organisatie groeit</li>
                    <li className="streamer">2. Je kosten wilt besparen</li>
                    <li className="streamer">3. Je je administratie anders wilt uitbesteden</li>

                    </ul>
                    <p className="streamer margin-s-bottom">
                    In dit whitepaper ontdek je hoe HR software ook voor jouw organisatie kan werken, zodat jij je kunt focussen op wat echt belangrijk is: je medewerkers!
                    </p>
                  </div>
                </div>

                <div className="col-6" style={{display: 'flex', alignItems: 'center',justifyContent: 'center',flexDirection: 'column'}}>

                  <div className="download-card">
                      <div className="form margin-s-bottom">
                        <h4 className="margin-s-bottom">Vul je gegevens in om de whitepaper te ontvangen</h4>
                        <form name="Whitepaper | Hoe word je een held in HR?" method="POST" action="" data-netlify="true" data-netlify-honeypot="bot-field" action="/whitepapers/">
                          <input type="hidden" name="form-name" value="Whitepaper | Hoe word je een held in HR?" />
                          <input type="text" name="firstname" placeholder="Voornaam*" className="margin-xs-bottom" autoFocus required/>
                          {/*<input type="text" name="lastname" placeholder="Achternaam*" className="margin-xs-bottom" required/>*/}
                          <input type="email" name="email" placeholder="Emailadres*" className="margin-xs-bottom" required/>
                          <input type="text" name="phone" placeholder="Telefoonnummer*" className="margin-xs-bottom"/>
                          <input type="text" name="company" placeholder="Bedrijfsnaam*" className="margin-xs-bottom" required/>
                          <button type="submit" className="btn primary lg">Download gratis</button>
                        </form>
                      </div>

                      <div>
                        <Link className="link mulberry" to="/privacy-policy/" >Privacy policy</Link>
                      </div>
                  </div>

                </div>

              </div>
            </div>

          </header>


        </main>
      </Layout>
    )
  }
}

export default DemoPage
