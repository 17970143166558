import React from 'react';
const TrustBox = () => {

  const ref = React.useRef(null);
  React.useEffect(() => {

if (window.Trustpilot) {
  window.Trustpilot.loadFromElement(ref.current, true);
}
  }, []);
  return (
    <div
       ref={ref}
       className="trustpilot-widget"
       data-locale="nl-NL"
       data-template-id="539ad60defb9600b94d7df2c"
       data-businessunit-id="58fe12490000ff0005a11d05"
       data-style-height="750px"
       data-style-width="100%"
       data-theme="light"
      >
      <a href="https://nl.trustpilot.com/review/employes.nl" target="_blank" rel="noopener"> Trustpilot</a>
    </div>
  );
};

export default TrustBox;
